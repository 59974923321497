import React from "react";
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import { Box, Button, Card, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getCompanyLogo } from '@coverright/utils';
import { Benefit } from '@coverright/ui/marketplaces';
import { Plan } from '@coverright/data-access/types/medicare';
import { getDeductible } from '@coverright/utils';

export const planStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      [theme.breakpoints.up('sm')]: {
        padding: '16px',
      },
      padding: '16px 10px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    logo: {
      [theme.breakpoints.up('sm')]: {
        height: 33,
        width: 119,
        minWidth: 119,
        marginTop: 9,
        backgroundPosition: 'right'
      },
      backgroundPosition: 'left',
      height: 33,
      width: 119,
      minWidth: 119,
      marginTop: 0,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
  }),
);

type PlanCardProps = {
  plan: Plan,
  onClick: () => void
}

const tooltipImgProps = {
  width: 12,
  height: 12,
}

function MedicarePlanCard({plan, onClick} : PlanCardProps) {
  const classes = planStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  const ref = React.useRef(null);


  const Desktop = <Card className={classes.card}>
    <div className={'crw-flex-align-center crw-flex-space-between crw-mb-20'}>
      <div className={classes.logo} style={{backgroundImage: `url("${getCompanyLogo(plan.parentOrgName, plan.orgName)}")`}}  />
      <QuotesButton variant={'contained'} onClick={onClick}>Get quotes</QuotesButton>
    </div>
    <div className={`${phone ? 'crw-flex-column' : 'crw-flex'}`}>
      <div className={'crw-flex-column'}>
        <Typography color={'textPrimary'} className={'crw-bold crw-fs-20 crw-mb-12'}>{plan.planName}</Typography>
        <div className={`${phone ? 'crw-flex-column' : 'crw-flex'}`}>
          <div className={'crw-flex-column'}>
            <Typography color={'textPrimary'} className={'crw-bold crw-fs-12 crw-mb-8'}>Monthly premium:</Typography>
            <Typography className={'crw-bold crw-dark-green crw-fs-20 crw-mb-8 crw-lh-25'}>{plan.monthlyPremium}</Typography>
            <Typography color={'textPrimary'} className={'crw-fs-10'}>Excludes standard Part B premium ({getDeductible('standardPartBMonthlyPremium')})</Typography>
          </div>
          <div className={`crw-flex-column ${phone ? 'crw-mv-15' : ''}`}>
            <Typography color={'textPrimary'} className={`crw-bold crw-fs-12 crw-mb-8`}>Primary care doctor</Typography>
            <Typography className={'crw-bold crw-dark-green crw-fs-20 crw-lh-20'}>{plan?.primaryCarePhysicianShort || 'N/A'}</Typography>
            <Typography className={'crw-bold crw-dark-green crw-fs-12 crw-mb-8 crw-lh-15'}>(in network)</Typography>
            <Typography color={'textPrimary'} className={'crw-fs-10'}>Out-of-network: {plan?.planType === 'HMO' ? 'n.a.' : plan.primaryCarePhysicianOutOfNetwork}</Typography>
          </div>
          <div className={'crw-flex-column'}>
            <Typography color={'textPrimary'} className={'crw-bold crw-fs-12 crw-mb-8'}>Max out-of-pocket</Typography>
            <Typography className={'crw-bold crw-dark-green crw-fs-20 crw-lh-20'}>{plan.outOfPocketAmount || 'N/A'}</Typography>
            <Typography className={'crw-bold crw-dark-green crw-fs-12 crw-mb-8 crw-lh-15'}>(in network)</Typography>
            <Typography color={'textPrimary'} className={'crw-fs-10'}>Out-of-network: {plan?.planType === 'HMO' ? 'n.a.' : plan.maxEnrollmentAmount}</Typography>
          </div>
        </div>
      </div>
      <Box borderLeft={phone ? undefined : '1px solid #E8ECED'} borderTop={phone ? '1px solid #E8ECED' : undefined} className={`${phone ? 'crw-pt-10 crw-mt-10' : 'crw-w-235 crw-pl-35'}`}>
        <div className={'crw-flex crw-mb-4'}>
          <Typography color={'textPrimary'} className={'crw-bold crw-fs-12 crw-mr-8'}>Prescription drug coverage:</Typography>
          <Benefit label={''} value={plan.extraBenefits.drugCoverage}/>
        </div>
        <Typography color={'textPrimary'} className={'crw-bold crw-fs-12 crw-mb-4'}>Extra benefits:</Typography>
        <div className={'crw-flex'}>
          <div>
            <Benefit className={'crw-mb-4'} label={'Dental'} value={plan.extraBenefits.dental}/>
            <Benefit className={'crw-mb-4'} label={'Hearing'} value={plan.extraBenefits.hearing}/>
            <Benefit className={'crw-mb-4'} label={'Fitness'} value={plan.extraBenefits.fitness}/>
          </div>
          <div className={'crw-flex-column crw-ml-16'}>
            <Benefit className={'crw-mb-4'} label={'Vision'} value={plan.extraBenefits.vision}/>
            <Benefit className={'crw-mb-4'} label={'Transportation'} value={plan.extraBenefits.transportation}/>
            <Benefit className={'crw-mb-4'} label={'Telehealth'} value={plan.extraBenefits.telehealth}/>
          </div>
        </div>
        <Benefit label={'Insulin savings'} value={plan.extraBenefits.insulinSavings}/>
      </Box>
    </div>
  </Card>;


  return <div className={`crw-mt-24 ${classes.wrapper}`} ref={ref}>
    {Desktop}
  </div>;
}

export default React.memo(MedicarePlanCard);

const QuotesButton = withStyles((theme: Theme) =>
  createStyles({
    contained: {
      borderRadius: 4,
      padding: '8px',
      backgroundColor: '#0A2E86',
      maxHeight: 30,
      width: 197,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    label: {
      color: 'white',
      fontSize: 12,
      lineHeight: '15px',
      textTransform: 'none',
      fontWeight: 700,
    },
  }))(Button)
